<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="deviceCatId"
                        label="设备类型"
                        label-width="9em"
                    >
                        <CascaderPicker
                            :api-class="selectApi"
                            v-model="formModel.deviceCatId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="deviceName"
                        label="设备名称"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.deviceName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="deviceSn"
                        label="设备编号"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.deviceSn"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="addressKeywords"
                        label="地址关键词"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.addressKeywords"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="9em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAvailable"
                        label="是否可用"
                        label-width="9em"
                    >
                        <el-switch
                            v-model="formModel.isAvailable"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="9em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'DeviceEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                deviceName: '', // 设备名称
                deviceSn: '', // 设备编号
                addressKeywords: '',
                remarks: '',
                isAvailable: true, // 是否有效
                deviceCatId: '', // 设备类型
            },
            // 表单校验规则
            formRules: {
                deviceName: [
                    {
                        required: true,
                        message: '请输入设备名称',
                        trigger: 'blur',
                    },
                ],
                deviceSn: [
                    {
                        required: true,
                        message: '请输入设备编号',
                        trigger: 'blur',
                    },
                ],
                deviceCatId: [
                    {
                        required: true,
                        message: '请选择设备类型',
                    },
                ],
            },
            selectApi: this.$api.Sp.DeviceCat,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.Device.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initDeviceDetail() {
            this.$api.Sp.Device.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.initDeviceDetail();
        }
    },
};
</script>

<style lang="scss">
</style>
