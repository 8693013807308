<!-- Created by henian.xu on 2018/7/20. -->
<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                @keyup.enter.native="onQuery"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="deviceCatId"
                    label="设备类型名称"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.deviceCatId"
                        check-strictly
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceName"
                    label="设备名称"
                >
                    <el-input
                        v-model="queryFormModel.deviceName"
                        placeholder="请输入设备名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="deviceSn"
                    label="设备编号"
                >
                    <el-input
                        v-model="queryFormModel.deviceSn"
                        placeholder="请输入设备编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopName"
                    label="店铺名称"
                >
                    <el-input
                        v-model="queryFormModel.shopName"
                        placeholder="请输入店铺名称"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        placeholder="请输入门店名称"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/sp/device/device/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
                <el-button-group>
                    <el-button
                        type="danger"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="39"
                />
                <el-table-column
                    prop="name"
                    label="设备类型名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceName"
                    label="设备名称"
                    min-width="100"
                />
                <el-table-column
                    prop="deviceSn"
                    label="设备编号"
                    min-width="100"
                />
                <el-table-column
                    prop="shopName"
                    label="店铺名称"
                    min-width="100"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/sp/device/device/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="cancelRelationDevice(scope.row.id)"
                            v-if="scope.row.storeName"
                        >
                            取消关联
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Warehouse',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                deviceCatId: '', // 设备分类
                deviceName: '', // 设备名称
                deviceSn: '',
                shopName: '',
                storeName: '',
                isAvailable: '', // 是否有效
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Sp.DeviceCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.Device.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        // 取消关联
        cancelRelationDevice(id) {
            this.$api.Sp.Device.cancelRelationDevice({
                id,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery();
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sp.Device.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
